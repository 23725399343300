import Vue from 'vue'
import App from './App.vue'
import router from './router';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import 'popper.js';
import { BootstrapVue, IconsPlugin, CarouselPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { BCarousel } from 'bootstrap-vue'
import 'bootstrap/dist/js/bootstrap.bundle.js';
Vue.component('b-carousel', BCarousel)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(CarouselPlugin);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
