<template>
    <div class="detail-page">
        <div class="hamburger-icon" @click="toggleMenu">
            <div :class="{ 'bar1': isMenuOpen }"></div>
            <div :class="{ 'bar2': isMenuOpen }"></div>
            <div :class="{ 'bar3': isMenuOpen }"></div>
        </div>

        <!-- Sidebar Menu -->
        <nav :class="['sidebar', { 'open': isMenuOpen }]">
            <ul>
                <li><a href="#">สถานที่ท่องเที่ยว</a></li>
                <li @click="onClickBackHomePage"><a>กลับหน้าแรก</a></li>
            </ul>
        </nav>
        <h2 class="mt-3">สถานที่ท่องเที่ยวแนะนำ</h2>
        <div v-if="detail.length > 0" id="section3" class="section">
            <div class="row">
                <div v-for="(res, index) in detail" :key="index" class="col-12 mb-4">
                    <img :src="res.image" alt="" class="img-fluid">
                    <h5 class="mt-2">{{ res.title }}</h5>
                    <hr>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { data } from './travel.js';

export default {
    name: "Travel",
    data() {
        return {
            detail: [],
            isMenuOpen: false,
        };
    },
    created() {
        console.log('data: ', data)
        this.detail = data;
    },
    methods: {
        onClickBackHomePage() {
            this.$router.push({ name: 'Home' });
        },
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
                this.isMenuOpen = false;
            }
        },
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        }
    }
};
</script>

<style scoped>
.img-fluid {
    width: auto;
    height: 450px;
    object-fit: cover;
    border-radius: 5px;
}
.col-12.col-md-6.col-lg-3 {
    text-align: center; /* จัดข้อความให้อยู่ตรงกลางคอลัมน์ */
}

.col-12.col-md-6.col-lg-3 img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
}

.col-12.col-md-6.col-lg-3 h5 {
    margin-top: 10px;
}

.col-3 img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    display: block;
    margin-bottom: 10px;
}

.detail-page {
    display: block;
}

/* Hamburger Menu Icon */
.hamburger-icon {
    position: fixed;
    top: 20px;
    left: 20px;
    cursor: pointer;
    z-index: 1000;
}

.hamburger-icon div {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

/* Change Icon to X when Menu is Open */
.bar1 {
    transform: rotate(-45deg) translate(-9px, 6px);
}

.bar2 {
    opacity: 0;
}

.bar3 {
    transform: rotate(45deg) translate(-8px, -8px);
}

/* Sidebar */
.sidebar {
    position: fixed;
    top: 0;
    left: -175px;
    width: 250px;
    height: 100%;
    background-color: #f0f0f0;
    padding: 20px;
    transition: 0.4s;
    z-index: 999;
}

.sidebar.open {
    left: 0;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ul li {
    margin-bottom: 20px;
}

.sidebar ul li a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
    cursor: pointer;
}

/* Main Content */
.content {
    margin-left: 220px;
    padding: 20px;
    width: calc(100% - 220px);
}

.section {
    margin-bottom: 100px;
}

.section h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .content {
        margin-left: 0;
        width: 100%;
    }

    .hamburger-icon {
        top: 10px;
        left: 10px;
    }
}
</style>