export const data = [
  {
    "title": "วัดพระใหญ่",
    "image": require("@/assets/wat-phra-yai.jpg")
  },
  {
    "title": "หาดเฉวง",
    "image": require("@/assets/chaweng-beach.jpg")
  },
  {
    "title": "หาดละไม",
    "image": require("@/assets/lamai-beach.jpg")
  },
  {
    "title": "หินตาหินยาย",
    "image": require("@/assets/grandfather-and-grandma-stone.jpg")
  },
  {
    "title": "น้ำตกหน้าเมือง",
    "image": require("@/assets/namuang-waterfall.jpg")
  },
]
