<template>
    <div class="home">
        <Header />
        <CarouselHome />
        <div class="spots-container">
            <div class="spots">
                <TouristSpot id="1" title="วัดพระใหญ่" description="Wat Phra Yai" :image="require('@/assets/wat-phra-yai.jpg')" />
                <TouristSpot id="2" title="หาดเชิงมน" description="Choeng Mon Beach" :image="require('@/assets/choeng-mon-beach.jpg')" />
                <TouristSpot id="3" title="น้ำตกหินลาด" description="Hin Lad Waterfall" :image="require('@/assets/hin-lad-waterfall.jpg')" />
            </div>
            <div class="spots">
                <TouristSpot id="4" title="วัดปลายแหลม" description="Plai Laem Temple" :image="require('@/assets/plai-laem-temple.jpg')" />
                <TouristSpot id="5" title="หาดเฉวง" description="Chaweng Beach" :image="require('@/assets/chaweng-beach.jpg')" />
                <TouristSpot id="6" title="น้ำตกหน้าเมือง" description="Namuang Waterfall" :image="require('@/assets/namuang-waterfall.jpg')" />
            </div>
            <div class="spots">
                <TouristSpot id="7" title="วัดคุณาราม" description="Khunaram Temple" :image="require('@/assets/khunaram-temple.jpg')" />
                <TouristSpot id="8" title="หาดละไม" description="Lamai Beach" :image="require('@/assets/lamai-beach.jpg')" />
                <TouristSpot id="9" title="น้ำตกตานิ่ม" description="Tanim Waterfall" :image="require('@/assets/tanim-waterfall.jpg')" />
            </div>
            <div class="spots">
                <TouristSpot id="10" title="วัดเจดีย์แหลมสอ" description="Chedi Laem So Temple" :image="require('@/assets/chedi-laem-so-temple.jpg')" />
                <TouristSpot id="11" title="หาดแม่น้ำ" description="Mae Nam Beach" :image="require('@/assets/mae-nam-beach.jpg')" />
                <TouristSpot id="12" title="หินตาหินยาย" description="Grandfather and Grandma Stone" :image="require('@/assets/grandfather-and-grandma-stone.jpg')" />
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import TouristSpot from "@/components/TouristSpot.vue";
import Footer from "@/components/Footer.vue";
import CarouselHome from "../components/CarouselHome.vue";

export default {
    name: "Home",
    components: {
        Header,
        TouristSpot,
        Footer,
        CarouselHome,
    }
};
</script>

<style scoped>
.home {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.spots-container {
    flex: 1;
}

.spots {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}
</style>
