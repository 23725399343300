<template>
    <nav class="navbar navbar-dark bg-dark">
        <div class="container-fluid">
            <div class="navbar-brand">
               <img src="@/assets/image/logoMenu.png" alt="logo" width="auto" height="80" class="d-inline-block align-text-top">
            </div>
            <h1 class="navbar-brand" @click="onClickHomePage">หน้าหลัก</h1>
            <h1 class="navbar-brand" @click="onClickTravel">สถานที่ท่องเที่ยวแนะนำ</h1>
            <h1 class="navbar-brand" @click="onClickFamouse">ร้านอาหารขึ้นชื่อ</h1>
        </div>
    </nav>
</template>

<script>

export default {
    name: "Header",
    methods: {
        onClickHomePage() {
        },
        onClickFamouse() {
            this.$router.push({ name: 'FamousFood' });
        },
        onClickTravel() {
            this.$router.push({ name: 'Travel' });
        }
    }
};
</script>

<style scoped>
.header {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 10px 0;
}
</style>