import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import DetailPage from '@/views/DetailPage.vue';
import FamousFood from '@/views/Famousfood.vue';
import Travel from '@/views/Travel.vue';
Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/detail/:id',
      name: 'DetailPage',
      component: DetailPage,
      props: true,
    },
    {
      path: '/famousfood',
      name: 'FamousFood',
      component: FamousFood,
    },
    {
      path: '/travel',
      name: 'Travel',
      component: Travel,
    },
  ],
});
