<template>
    <div class="tourist-spot" @click="goToDetailPage">
        <div class="image-container">
            <img :src="image" :alt="title" />
        </div>
        <h2>{{ title }}</h2>
        <p>{{ description }}</p>
    </div>
</template>

<script>
export default {
    name: "TouristSpot",
    props: {
        title: String,
        description: String,
        image: String,
        id: [String, Number]
    },
    mounted() {
        console.log('this.image: ', this.image);
    },
    methods: {
        goToDetailPage() {
            if (this.$router && this.id) {
                this.$router.push({ name: 'DetailPage', params: { id: this.id } })
                    .catch(err => {
                        if (err.name !== 'NavigationDuplicated') {
                            console.error(err); // ถ้าไม่ใช่ NavigationDuplicated ให้ log ข้อผิดพลาดอื่น ๆ
                        }
                    });
            } else {
                console.error("Router is not available or id is missing");
            }
        }
    }
};
</script>

<style lang="css" scoped>
.tourist-spot {
    text-align: center;
}

.image-container {
    width: 100%;
    max-width: 300px;
    height: 200px;
    overflow: hidden;
    margin: 0 auto;
}

.tourist-spot img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* จัดการภาพให้ครอบคลุมทั้งพื้นที่ container โดยที่ไม่ทำให้ภาพบิดเบี้ยว */
    border-radius: 10px;
}

.tourist-spot h2 {
    color: #333;
    margin: 10px 0;
}

.tourist-spot {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}

@media (max-width: 920px) {
    .tourist-spot img {
        width: 80%;
        /* ลดขนาดภาพลงบนหน้าจอเล็ก */
    }
}
</style>
