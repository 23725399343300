<template>
    <footer class="footer">
        <p>&copy; 2024 แหล่งท่องเที่ยวทั้งหมด</p>
    </footer>
</template>

<script>
export default {
    name: "Footer"
};
</script>

<style scoped>
.footer {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 10px 0;
}
</style>