export const data = [
  {
    "title": "ครัวชาวบ้านสมุย",
    "image": require("@/assets/samui.png")
  },
  {
    "title": "เมอริท ไลฟ์ คาเฟ่",
    "image": require("@/assets/life.png")
  },
  {
    "title": "ร้านอาหารกะปิสะตอ",
    "image": require("@/assets/tor.png")
  },
  {
    "title": "ร้านอาหารข้าวหอม",
    "image": require("@/assets/hom.png")
  },
  {
    "title": "ร้านอาหารครัวสมุย",
    "image": require("@/assets/kui.png")
  },
  {
    "title": "ร้านอาหารเจ๊มุ๊ย",
    "image": require("@/assets/mui.png")
  },
  {
    "title": "ร้านอาหารมายเฟรนด์",
    "image": require("@/assets/my.png")
  },
]
